<template>
  <b-card 
    border-variant="secondary"
    :img-src="image"
    img-alt="Image"
    img-top
    tag="article"
    @click="goCourse(id)"
    class="card-article card-course"
    style="border-radius: 15px; height: 100%; overflow: hidden"
    
  >
    <div class="card-title" v-text="header" :title="header" ></div>
    <div class="short-desc" v-html="content" :title="replacedText"></div>
    <template #footer>
      <!-- <pre>{{ enroll }}</pre> -->
    <div v-if="paid_type == 'paid' && status != 'paid'">
      <div v-if="(status == 'waiting' || status == 'pending') && enroll.user_enroll.length === 0" >

        <span class="price-course">฿{{ price }}</span>
        <div class="d-flex justify-content-center">
          <b-button variant="light" class="w-100 m-1 z-3 main-orange-btn-outline">กําลังดําเนินการ</b-button>
        </div>
      </div>
 
      <div v-else-if="enroll.user_enroll.length === 0" @click.stop="checkCoursepPaidType(id)">
        <span class="price-course">฿{{ price }}</span>
        <div class="d-flex justify-content-center" >
          <b-button variant="light" class="w-100 m-1 z-3 main-orange-btn-outline" :disabled="isDisable()" >{{cardText()}}</b-button>
        </div>
      </div>
    </div>
    <!-- <div v-else> 
      <div v-if="cardText()==='คอร์สเรียนสิ้นสุด'">
        <span class="price-course">฿{{ price }}</span>
        <div class="d-flex justify-content-center" >
          <b-button variant="light" class="w-100 m-1 z-3 main-orange-btn-outline" :disabled="isDisable()" >{{cardText()}}</b-button>
        </div>
      </div>
    </div>
    <div >paid_type : {{ paid_type }}</div>
    <div >status : {{ status }}</div>
    <div >enroll : {{ enroll }}</div> -->

  </template>
  </b-card>
 
</template>

<script>
import moment from "moment";

export default {
  props: ["header", "content", "image", "id","paid_type","price","status","approve_right","access_rights","enroll"],
  computed: {
    replacedText() {
      return this.content
        .replace(/<p>/g, '')
        .replace(/<\/p>/g, '');
    },
    
  },
  methods: {
    async goCourse(id) {
      const path = `/courses`;
      await this.$router.push({ path: path, query: { id: id } });
    },

    checkCoursepPaidType(id) {
      this.$emit('open-modal',id);
    }
    ,isDisable(){
      let isDisable = true;

      if(this.enroll){
        let enroll = this.enroll;
        let currentDate = moment();
        let registerStart = enroll?moment(enroll.register_start):false;
        let registerEnd = enroll?new moment(enroll.register_end):false;

        let courseStart = enroll?new moment(enroll.course_start):false;
        let courseEnd = enroll?new moment(enroll.course_end):false;

        if( (registerStart && registerEnd) && (courseStart && courseEnd) ){
          if(this.access_rights === 'public' || (this.access_rights === 'private' && this.approve_right !== null) ){
            if(enroll.user_enroll.length > 0 && currentDate <= courseEnd){
              isDisable = false;
            }else if ( enroll.user_enroll.length == 0 && currentDate < registerEnd  ) { 
              isDisable = false;
            } 
          }
        }
      }
      return isDisable;
    }
    
    ,cardText(){
      let text = 'ซื้อคอร์สนี้';
      console.log('access_rights : ',this.access_rights,' - ','approve_right : ',this.approve_right)
      console.log('this.enroll : ',this.enroll)
      let currentDate = moment();
      let enroll = this.enroll ; 
      let registerStart = enroll?moment(enroll.register_start):false;
      let registerEnd = enroll?new moment(enroll.register_end):false;

      let courseStart = enroll?new moment(enroll.course_start):false;
      let courseEnd = enroll?new moment(enroll.course_end):false;
      if( (this.access_rights === 'private' && this.approve_right !== null) || (this.access_rights === 'public') ){

        if ( !(currentDate.isBetween(registerStart,registerEnd)) && enroll.user_enroll.length === 0) { 
          text = 'ปิดรับสมัคร'
        }else if( !(currentDate.isBetween(courseStart,courseEnd)) && enroll.user_enroll.length > 0  ){
          text = 'คอร์สเรียนสิ้นสุด'
        } 
      }else{
        if ( !(currentDate.isBetween(registerStart,registerEnd)) && enroll.user_enroll.length === 0) { 
          text = 'ปิดรับสมัคร'
        }else if( !(currentDate.isBetween(courseStart,courseEnd)) && enroll.user_enroll.length > 0  ){
          text = 'คอร์สเรียนสิ้นสุด'
        } 
      }
      return text;
    }
  },
};
</script>

<style>
.card-title {
  font-size: 18px;
  font-weight: bold;
}

.price-course{
    font-weight: 700;
    font-size: 20px;
}
</style>
